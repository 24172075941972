import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'

Vue.use(Vuex)

const modulesFiles = require.context('./modules', true, /\.js$/)
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})

const store = new Vuex.Store({
  state: {
    flutter_baseInfo: {},
    flutter_cId: '',
    ifscCode: '',
  },
  getters: {
    ...getters,
  },
  mutations: {
    getFromApp(state, n) {
      state.flutter_baseInfo = n
    },
    getCustomerId(state, n) {
      state.flutter_cId = n
    },
    getIfscCode(state, n) {
      state.ifscCode = n
    },
    SET_FLUTTER_BASE_INFO(state, info) {
      state.flutter_baseInfo = info;
    },
  },
  actions: {
    async fetchFlutterBaseInfo({ commit }) {
      try {
        const result = await nativeBridgeConfig.nativeCall("getBaseInfo");
        if (result) {
          localStorage.setItem("BaseInfo", result);
          localStorage.setItem("appCode", JSON.parse(result).appCode);

          const bsInfo = {
            appCode: Number(JSON.parse(result).appCode),
            version: JSON.parse(result).versionCode,
            userCode: Number(JSON.parse(result).customerId),
            imei: JSON.parse(result).imei,
            idfv: JSON.parse(result).idfv,
            idfa: JSON.parse(result).idfa,
            ip: JSON.parse(result).ip,
            longitude: JSON.parse(result).longitude,
            latitude: JSON.parse(result).latitude,
            token: JSON.parse(result).token
          };

          commit('SET_FLUTTER_BASE_INFO', bsInfo);
        }
        if (!JSON.parse(result).token) {
          await nativeBridgeConfig.nativeCall("loginOut");
        }
      } catch (error) {
        if (error.message === "SessionExpired") {
          try {
            await nativeBridgeConfig.nativeCall("loginOut");
          } catch (logoutError) {
            console.error(`loginOut-error: "${logoutError}"`);
          }
        } else {
          console.error("An unexpected error occurred.");
        }
      }
    },
  },
  modules,
});

export default store
