let nativeBridgeConfig = {
  environment: null,
  nativeCall: null
};
let iosData = null;
let pendingRequests = {};
function initNativeBridge() {
  if (typeof window.flutter_inappwebview !== 'undefined') {
    console.log('flutter_inappwebview');
    nativeBridgeConfig.environment = 'Flutter InAppWebView';
    nativeBridgeConfig.nativeCall = function (methodName, ...args) {
      try {
        const result = window.flutter_inappwebview.callHandler(methodName, ...args);
        return Promise.resolve(JSON.stringify(result));
      } catch (error) {
        return Promise.reject(error);
      }
    };
  } else if (typeof window.android !== 'undefined') {
    console.log('android');
    nativeBridgeConfig.environment = 'Native Android';
    nativeBridgeConfig.nativeCall = function (methodName, ...args) {
      try {
        const result = window.android[methodName](...args);
        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      }
    };
  } else if (typeof window.webkit !== 'undefined' && window.webkit.messageHandlers) {
    // alert('iOS');
    console.log('iOS');
    nativeBridgeConfig.environment = 'iOS';
    console.log(window.webkit.messageHandlers.myResponseHandler, '((((((((((((((((')
    window.shareResult = shareResult;
    nativeBridgeConfig.nativeCall = function (methodName, ...args) {
      return new Promise((resolve, reject) => {
        try {
          window.webkit.messageHandlers[methodName].postMessage({ args });
          const checkInterval = setInterval(() => {
            if (iosData != null) {
              clearInterval(checkInterval);
              if (iosData !== undefined) {
                return resolve(iosData);
              } else {
                reject(new Error('Invalid data received'));
              }
            }
          }, 100);
          const timeout = setTimeout(() => {
            clearInterval(checkInterval);
            reject(new Error('Request timed out'));
          }, 5000);
          return () => {
            clearTimeout(timeout);
          };
        } catch (error) {
          return reject(error);
        }
      });
    };
  } else {
    nativeBridgeConfig.environment = 'Unknown';
    nativeBridgeConfig.nativeCall = () => {
      throw new Error('Unsupported environment for native calls.');
    };
  }
}
function generateRequestId() {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}
function shareResult(strings) {
  iosData = strings;
}
export { initNativeBridge, nativeBridgeConfig };
