import moment from 'moment';
export default {
  getArea: function (number) {
    return String(number).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
  getDate: function (data) {
    const date = moment(data, 'DD-MM-YYYY');
    if (date.isValid()) {
      const formattedDate = date.format('MMM D, YYYY');
      return formattedDate;
    } else {
      console.error('Invalid date format:', data);
      return null;
    }
  },
};
