import Cookies from 'js-cookie'
let name = 'HEADER_APP_AUTH_TOKEN'
let names = '3C3D2E313B3D113C'
//token
export function setToken(value) {
  console.log(value,'!@%%^**(())')
	Cookies.set(name, value)
}
export function getToken() {
	Cookies.get(name)
}
export function removeToken() {
	Cookies.remove(name)
}

export function setEquipmentNo(value) {
	Cookies.set(names, value)
}
export function getEquipmentNo() {
	Cookies.get(names)
}
export function removeEquipmentNo() {
	Cookies.remove(names)
}
